import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useIntl, FormattedMessage, Link } from 'gatsby-plugin-intl'

import Layout from '../../../components/Layouts/layout'
import SEO from '../../../components/seo'
import SubPageNav from '../../../components/SubPageNav/SubPageNav'
import SubPageBottomNav from '../../../components/SubPageBottomNav/SubPageBottomNav'
import Alert from '../../../components/Alert/Alert'
import ToolTip from '../../../components/ToolTip/ToolTip'

const BuilderNewApplicantsPage = () => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query {
      builderImg: file(relativePath: { eq: "mintoBuilder02.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      builderGroup: file(relativePath: { eq: "mintoBuilderGroup.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout
      view='builder'
      breadcrumb={{
        text: <FormattedMessage id='builderNav.dashboard' />,
        path: '/builder-vendor/dashboard/'
      }}
      header={
        <>
          <FormattedMessage id='builderNav.becomeA' />{' '}
          <span style={{ fontWeight: `bold` }}>
            <FormattedMessage id='builderNav.becomeB' />
          </span>
        </>
      }
    >
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'builderNav.step3' })}
      />
      <Container>
        <Row>
          <Col>
            <SubPageNav
              links={[
                {
                  text: `${intl.formatMessage({ id: 'builderNav.step1' })}?`,
                  path:
                    '/builder-vendor/become-a-licensed-builder/do-i-need-a-licence'
                },
                {
                  text: <FormattedMessage id='builderNav.step2' />,
                  path:
                    '/builder-vendor/become-a-licensed-builder/before-you-apply'
                },
                {
                  text: <FormattedMessage id='builderNav.step3' />,
                  path:
                    '/builder-vendor/become-a-licensed-builder/applying-for-a-licence',
                  active: true
                },
                {
                  text: <FormattedMessage id='builderNav.step4' />,
                  path:
                    '/builder-vendor/become-a-licensed-builder/after-your-application-is-submitted/'
                },
                {
                  text: <FormattedMessage id='builderNav.step5' />,
                  path:
                    '/builder-vendor/become-a-licensed-builder/after-licensing'
                }
              ]}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <h2 className='underline'>
              <FormattedMessage id='becomeBuilder3.headerA' />
            </h2>
          </Col>

          <Col md={12}>
            <h3>
              <FormattedMessage id='becomeBuilder3.subHdrA' />
            </h3>
            <p>
              <FormattedMessage id='becomeBuilder3.contentA' />{' '}
              <Link to='/builder-vendor/new-application-checklist/'>
                <FormattedMessage id='becomeBuilder3.contentAii' />
              </Link>
              . <FormattedMessage id='becomeBuilder3.contentAiii' />{' '}
              <a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>.
            </p>
          </Col>

          <Col md={12}>
            <Alert header={<FormattedMessage id='becomeBuilder3.subHdrAi' />}>
              <p>
                <FormattedMessage id='becomeBuilder3.contentBi' />{' '}
                <ToolTip
                  link='/glossary/#principal'
                  internal
                  definition={
                    <FormattedMessage id='glossaryTerms.principalDef' />
                  }
                >
                  <FormattedMessage id='glossaryTerms.principalTermB' />
                </ToolTip>
                ,{' '}
                <ToolTip
                  link='/glossary/#officer'
                  internal
                  definition={<FormattedMessage id='glossaryTerms.offDef' />}
                >
                  <FormattedMessage id='glossaryTerms.offTermC' />
                </ToolTip>
                ,{' '}
                <ToolTip
                  link='/glossary/#director'
                  internal
                  definition={<FormattedMessage id='glossaryTerms.dirDef' />}
                >
                  <FormattedMessage id='glossaryTerms.dirTermC' />
                </ToolTip>
                ,{' '}
                <ToolTip
                  link='/glossary/#partner'
                  internal
                  definition={
                    <FormattedMessage id='glossaryTerms.partnerDef' />
                  }
                >
                  <FormattedMessage id='glossaryTerms.partnerTermB' />
                </ToolTip>{' '}
                <FormattedMessage id='becomeBuilder3.contentBii' />{' '}
                <ToolTip
                  link='/glossary/#interestedPerson'
                  internal
                  definition={<FormattedMessage id='glossaryTerms.ipDef2' />}
                >
                  <FormattedMessage id='glossaryTerms.ipTermB' />
                </ToolTip>{' '}
                <FormattedMessage id='becomeBuilder3.contentBiii' />
              </p>
              <ul>
                <li>
                  <FormattedMessage id='becomeBuilder3.list01A' />{' '}
                </li>
                <li>
                  <FormattedMessage id='becomeBuilder3.list01B' />
                </li>
              </ul>
            </Alert>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Alert header={<FormattedMessage id='becomeBuilder3.subHdrB' />}>
              <ul>
                <li>
                  <FormattedMessage id='becomeBuilder3.list02A' />
                </li>
                <li>
                  <FormattedMessage id='becomeBuilder3.list02B' />
                </li>
              </ul>
            </Alert>
          </Col>
        </Row>

        <Row align='center' className='pageRow'>
          <Col md={6}>
            <h3>
              <FormattedMessage id='becomeBuilder3.subHdrC' />
            </h3>
            <p>
              <FormattedMessage id='becomeBuilder3.contentCi' />{' '}
              <ToolTip
                link='/glossary/#principal'
                internal
                definition={
                  <FormattedMessage id='glossaryTerms.principalDef' />
                }
              >
                <FormattedMessage id='glossaryTerms.principalTermC' />
              </ToolTip>
              ,{' '}
              <ToolTip
                link='/glossary/#director'
                internal
                definition={<FormattedMessage id='glossaryTerms.dirDef' />}
              >
                <FormattedMessage id='glossaryTerms.dirTermB' />
              </ToolTip>
              ,{' '}
              <ToolTip
                link='/glossary/#officer'
                internal
                definition={<FormattedMessage id='glossaryTerms.offDef' />}
              >
                <FormattedMessage id='glossaryTerms.offTermB' />
              </ToolTip>{' '}
              <FormattedMessage id='becomeBuilder3.contentCii' />{' '}
              <ToolTip
                link='/glossary/#partner'
                internal
                definition={<FormattedMessage id='glossaryTerms.partnerDef' />}
              >
                <FormattedMessage id='glossaryTerms.partnerTermC' />
              </ToolTip>{' '}
              <FormattedMessage id='becomeBuilder3.contentCiii' />
            </p>
            <ol className='largeNums'>
              <li>
                <div>
                  <h4>
                    <FormattedMessage id='becomeBuilder3.list03A_B' /> –{' '}
                    <span>
                      <ToolTip
                        link='/glossary/#umbrella'
                        internal
                        definition={
                          <FormattedMessage id='glossaryTerms.umbrellaDefB' />
                        }
                      >
                        <FormattedMessage id='glossaryTerms.umbrellaTermB' />
                      </ToolTip>
                    </span>
                  </h4>
                  <FormattedMessage id='becomeBuilder3.list03Ai' />{' '}
                  <strong>
                    <FormattedMessage id='becomeBuilder3.price01' />
                  </strong>{' '}
                  <FormattedMessage id='becomeBuilder3.list03Aii' />
                </div>
              </li>
              <li>
                <div>
                  <h4>
                    <FormattedMessage id='becomeBuilder3.list03B_B' />
                  </h4>
                  <FormattedMessage id='becomeBuilder3.list03Bi' />{' '}
                  <strong>
                    <FormattedMessage id='becomeBuilder3.price02' />
                  </strong>{' '}
                  <FormattedMessage id='becomeBuilder3.list03Bii' />
                </div>
              </li>
            </ol>
            <p>
              <FormattedMessage id='becomeBuilder3.contentCiv' />{' '}
              <a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>
              <FormattedMessage id='becomeBuilder3.contentCv' />
            </p>
          </Col>

          <Col md={6}>
            <Img fluid={data.builderGroup.childImageSharp.fluid} alt='' />
          </Col>
        </Row>

        <Row className='pageRow'>
          <Col md={12}>
            <h2 className='underline'>
              <FormattedMessage id='becomeBuilder3.headerB' />
            </h2>
            <p>
              <b>
                <FormattedMessage id='becomeBuilder3.contentD' />
              </b>
              <br />
              <FormattedMessage id='becomeBuilder3.contentDi' />
            </p>
            <p>
              <FormattedMessage id='becomeBuilder3.contentDii' />
            </p>
            <p>
              <FormattedMessage id='becomeBuilder3.contentDiii' />
            </p>
          </Col>
        </Row>

        <Row align='center'>
          <Col md={3}>
            <Img fluid={data.builderImg.childImageSharp.fluid} alt='' />
          </Col>
          <Col>
            <h3>
              <FormattedMessage id='becomeBuilder3.subHdrD' />
            </h3>
            <p>
              <FormattedMessage id='becomeBuilder3.contentEi' />{' '}
              <Link to='/builder-vendor/renewal-application-checklist/'>
                <FormattedMessage id='becomeBuilder3.contentEv' />
              </Link>
              . <FormattedMessage id='becomeBuilder3.contentEvi' />{' '}
              <a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>.
            </p>

            <p>
              <FormattedMessage id='becomeBuilder3.contentEii' />
            </p>
            <p>
              <FormattedMessage id='becomeBuilder3.contentEiii' />{' '}
              <a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>
              <FormattedMessage id='becomeBuilder3.contentEiv' />
            </p>
          </Col>
        </Row>

        <Row className='pageRow'>
          <Col>
            <h2 className='underline'>
              <FormattedMessage id='becomeBuilder3.headerC' />
            </h2>
            <p>
              <FormattedMessage id='becomeBuilder3.contentFi' />{' '}
              <ToolTip
                link='/glossary/#renewalDueDate'
                internal
                definition={<FormattedMessage id='glossaryTerms.renewDef' />}
              >
                <FormattedMessage id='glossaryTerms.renewTermB' />
              </ToolTip>{' '}
              <FormattedMessage id='becomeBuilder3.contentFii' />
            </p>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col md={12}>
            <SubPageBottomNav
              prevLink={{
                text: <FormattedMessage id='builderNav.step2' />,
                path:
                  '/builder-vendor/become-a-licensed-builder/before-you-apply/'
              }}
              nextLink={{
                text: <FormattedMessage id='builderNav.step4' />,
                path:
                  '/builder-vendor/become-a-licensed-builder/after-your-application-is-submitted'
              }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default BuilderNewApplicantsPage
